import { PanInfo } from 'framer-motion';
import throttle from 'lodash/throttle';
import {
  Dispatch,
  MutableRefObject,
  RefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useState
} from 'react';
import { useGameAudioPlayer } from 'students/views/pages/Games/common/helpers';
import { IItemBlock, IItemBlockRef, IItemMatch } from '../types';
import useArrangeItemsHint from './useArrangeItemsHint';

interface IReturnedValue {
  onDrag: (
    _event: MouseEvent | TouchEvent | PointerEvent,
    panInfo: PanInfo,
    _index: number
  ) => void;
  onDragStart: (
    _event: MouseEvent | TouchEvent | PointerEvent,
    panInfo: PanInfo,
    _index: number
  ) => void;
  onDragEnd: (
    _event: MouseEvent | TouchEvent | PointerEvent,
    panInfo: PanInfo,
    _index: number
  ) => void;
  answerItems: Array<IItemBlock>;
  hintVisible: boolean;
}

function useViewportDragHandlers({
  initBlocks,
  matchedItems,
  itemBlocks,
  itemBlocksRefs,
  addOneMistake,
  viewportRect,
  answersBlockRef,
  selectedIndexRef,
  setItemMatch,
  setMatchedItems
}: {
  initBlocks: (itemIndex: number) => void;
  matchedItems: Array<number>;
  itemBlocks: Array<IItemBlock>;
  itemBlocksRefs: Record<string, RefObject<IItemBlockRef>>;
  addOneMistake: () => void;
  viewportRect: DOMRect | null;
  answersBlockRef: MutableRefObject<HTMLDivElement | null>;
  selectedIndexRef: MutableRefObject<number>;
  setItemMatch: Dispatch<SetStateAction<IItemMatch>>;
  setMatchedItems: Dispatch<SetStateAction<number[]>>;
}): IReturnedValue {
  const [hoveringIndex, setHoveringIndex] = useState(-1);
  const { playCorrectSound, playIncorrectSound } = useGameAudioPlayer();
  const [answerItems, setAnswerItems] = useState<Array<IItemBlock>>([]);
  const [hintVisible, markInteracted, setHintNeeded] = useArrangeItemsHint();

  useEffect(() => {
    const firstItem = itemBlocks
      .filter((itemBlock: IItemBlock) => !itemBlock.origin)
      .find((itemBlock: IItemBlock) => itemBlock.option.position === 1);

    if (firstItem) {
      if (hintVisible) {
        itemBlocksRefs[firstItem.sequenceIndex].current?.triggerAnimation('hint');
      } else {
        itemBlocksRefs[firstItem.sequenceIndex].current?.cancelAnimation();
      }
    }
  }, [hintVisible, itemBlocksRefs, itemBlocks]);

  const getHoveringIndex = useCallback(
    (x: number, y: number, selectedWordPositionId: string) => {
      for (let i = 0; i < itemBlocks.length; i += 1) {
        const currentWordPositionRect =
          itemBlocks[i].originRef?.node.getBoundingClientRect();

        if (
          currentWordPositionRect &&
          itemBlocks[i].optionId !== selectedWordPositionId &&
          x >= currentWordPositionRect.x &&
          x <= currentWordPositionRect.x + currentWordPositionRect.width &&
          y >= currentWordPositionRect.y &&
          y <= currentWordPositionRect.y + currentWordPositionRect.height
        ) {
          return i;
        }
      }

      return -1;
    },
    [itemBlocks]
  );

  const centerBlockOnCursor = useCallback(
    (event: PointerEvent) => {
      const itemBlockRect =
        itemBlocks[selectedIndexRef.current]?.originRef?.node.getBoundingClientRect();

      itemBlocksRefs[selectedIndexRef.current]?.current?.setAnimation({
        x: -(event.x - (itemBlockRect?.x ?? 0)),
        y: -(event.y - (itemBlockRect?.y ?? 0))
      });
    },
    [itemBlocks, itemBlocksRefs, selectedIndexRef]
  );

  const animateBlockOnHoverOnAnswer = (panInfo: PanInfo) => {
    const answersBlockRect = answersBlockRef.current?.getBoundingClientRect();

    if (
      answersBlockRect &&
      panInfo.point.x >= answersBlockRect.x &&
      panInfo.point.x <= answersBlockRect.x + answersBlockRect.width &&
      panInfo.point.y >= answersBlockRect.y &&
      panInfo.point.y <= answersBlockRect.y + answersBlockRect.height
    ) {
      itemBlocksRefs[selectedIndexRef.current]?.current?.triggerAnimation(
        'hoverOnAnswersBlock'
      );

      itemBlocks[selectedIndexRef.current]?.originRef?.node.classList.add(
        'hoverOnAnswer'
      );
    } else {
      itemBlocksRefs[selectedIndexRef.current]?.current?.triggerAnimation('restoreSize');
      itemBlocks[selectedIndexRef.current]?.originRef?.node.classList.remove(
        'hoverOnAnswer'
      );
    }
  };

  const throttledAnimateHoverOnAnswer = throttle(
    (panInfo: PanInfo) => animateBlockOnHoverOnAnswer(panInfo),
    300
  );

  const checkOnScroll = useCallback(
    (panInfo: PanInfo) => {
      const MIN_SCROLL_OFFSET = 60;
      const answersBlockRect = answersBlockRef.current?.getBoundingClientRect();

      if (
        answersBlockRect &&
        answersBlockRect.x + answersBlockRect.width - panInfo.point.x < MIN_SCROLL_OFFSET
      ) {
        answersBlockRef.current && (answersBlockRef.current.scrollLeft += 1);
      }

      if (answersBlockRect && panInfo.point.x - answersBlockRect.x < MIN_SCROLL_OFFSET) {
        answersBlockRef.current && (answersBlockRef.current.scrollLeft -= 1);
      }

      if (panInfo.point.y - window.scrollY < MIN_SCROLL_OFFSET) {
        document.documentElement.scrollTop -= 1;
      }
    },
    [answersBlockRef]
  );

  const removeItemBlocksHoveringState = useCallback(() => {
    itemBlocks.forEach((block) => {
      block.originRef?.node.classList.remove('hovering');
    });
  }, [itemBlocks]);

  const onDrag = useCallback(
    (
      _event: MouseEvent | TouchEvent | PointerEvent,
      panInfo: PanInfo,
      _index: number
    ) => {
      throttledAnimateHoverOnAnswer(panInfo);
      markInteracted();
      checkOnScroll(panInfo);

      const hoveredItemIndex = getHoveringIndex(
        panInfo.point.x,
        panInfo.point.y,
        itemBlocks[selectedIndexRef.current].optionId
      );

      removeItemBlocksHoveringState();

      if (hoveredItemIndex !== -1) {
        itemBlocks[hoveredItemIndex].originRef?.node.classList.add('hovering');
      }
    },
    [
      markInteracted,
      throttledAnimateHoverOnAnswer,
      checkOnScroll,
      getHoveringIndex,
      removeItemBlocksHoveringState,
      itemBlocks,
      selectedIndexRef
    ]
  );

  const onDragStart = useCallback(
    (event: MouseEvent | TouchEvent | PointerEvent, info: PanInfo, index: number) => {
      selectedIndexRef.current = index;
      markInteracted();
      centerBlockOnCursor(event as PointerEvent);
    },
    [markInteracted, selectedIndexRef, centerBlockOnCursor]
  );

  const checkItemOnCorrectness = useCallback(
    (hoveredItemIndex: number) => {
      const isCorrect =
        itemBlocks[selectedIndexRef.current].option.id ===
        itemBlocks[hoveredItemIndex].option.id;

      if (isCorrect) {
        setItemMatch({
          status: 'correct',
          first: selectedIndexRef.current,
          second: hoveredItemIndex
        });
        setMatchedItems([...matchedItems, hoveredItemIndex, selectedIndexRef.current]);
        playCorrectSound();

        const correctBlock = itemBlocks[selectedIndexRef.current];
        setAnswerItems((prev) => {
          return [...prev, correctBlock];
        });
        setHintNeeded(false);
      } else {
        setItemMatch({
          status: 'incorrect',
          first: selectedIndexRef.current,
          second: hoveredItemIndex
        });
        playIncorrectSound();

        itemBlocksRefs[selectedIndexRef.current]?.current?.triggerAnimation(
          'restoreSize'
        );
        itemBlocks[selectedIndexRef.current]?.originRef?.node.classList.remove(
          'hoverOnAnswer'
        );

        addOneMistake();
      }

      setHoveringIndex(-1);
      removeItemBlocksHoveringState();
    },
    [
      itemBlocks,
      selectedIndexRef,
      setItemMatch,
      setMatchedItems,
      matchedItems,
      itemBlocksRefs,
      setHintNeeded,
      playCorrectSound,
      playIncorrectSound,
      addOneMistake,
      removeItemBlocksHoveringState
    ]
  );

  const onDragEnd = useCallback(
    (
      _event: MouseEvent | TouchEvent | PointerEvent,
      panInfo: PanInfo,
      _index: number
    ) => {
      if (selectedIndexRef.current === -1 || !viewportRect) {
        return;
      }

      const hoveredItemIndex = getHoveringIndex(
        panInfo.point.x,
        panInfo.point.y,
        itemBlocks[selectedIndexRef.current].optionId
      );

      if (
        hoveredItemIndex !== selectedIndexRef.current &&
        hoveredItemIndex !== hoveringIndex
      ) {
        setHoveringIndex(hoveredItemIndex);
      }

      if (hoveredItemIndex !== -1 && matchedItems.indexOf(hoveredItemIndex) === -1) {
        checkItemOnCorrectness(hoveredItemIndex);
      } else if (selectedIndexRef.current !== -1) {
        initBlocks(selectedIndexRef.current);
        itemBlocksRefs[selectedIndexRef.current]?.current?.triggerAnimation(
          'restoreSize'
        );
        itemBlocks[selectedIndexRef.current]?.originRef?.node.classList.remove(
          'hoverOnAnswer'
        );
      }

      selectedIndexRef.current = -1;
    },
    [
      hoveringIndex,
      initBlocks,
      matchedItems,
      itemBlocks,
      itemBlocksRefs,
      getHoveringIndex,
      viewportRect,
      selectedIndexRef,
      checkItemOnCorrectness
    ]
  );

  return {
    onDrag,
    onDragStart,
    onDragEnd,
    answerItems,
    hintVisible
  };
}

export default useViewportDragHandlers;
