import {
  IMinimalPairsTaskItem,
  useIsMounted
} from '@adeptlms/lingu-students-react-shared';
import { AnimationControls, useAnimation } from 'framer-motion';
import union from 'lodash/union';
import { useCallback, useEffect, useState } from 'react';
import { useHeartBeatMediaControl } from 'students/views/shared/components/HeartBeat';
import {
  useCheckForResize,
  useStopOnLeave
} from 'students/views/shared/components/MediaPlayer/VideoPlayer/hooks';

import { useMistakesCount } from '../../../common/hooks';
import usePlayAudioPlayer from '../../../common/hooks/usePlayAudioPlayer';
import useVideoLoading, { PLAYER_ID } from './useVideoLoading';

const BUTTONS_ANIMATION_DURATION = 500;

export interface ISelectAnswerPayload {
  itemId: number;
  optionId: number;
  mistakesCount: number;
}

export interface IMinimalPairsItem {
  item: IMinimalPairsTaskItem;
  onSelectAnswer: (payload: ISelectAnswerPayload) => void;
}
interface IReturnedValue {
  taskAnimationControl: AnimationControls;
  ref: (node: HTMLElement | null) => void;
  isLoading: boolean;
  isPlaying: boolean;
  videoHasError: boolean;
  isVideoUnsupported: boolean;
  handlePlay: () => void;
  isMediaWatched: boolean;
  handleMediaEnd: () => void;
  handleVideoError: (error: Error) => void;
  handleVideoPlayerError: () => void;
  handlePause: () => void;
  url: string;
  handleMediaReady: () => void;
  handleOptionClick: (optionId: number) => void;
  correctOption: number | null;
  currentWrongOptions: number[];
  isAudio: boolean;
}

function useMinimalPairsItem(
  item: IMinimalPairsTaskItem,
  onSelectAnswer: (payload: ISelectAnswerPayload) => void
): IReturnedValue {
  const [url, setUrl] = useState<string>('');
  const [isPlaying, setIsPlaying] = useState(false);
  const [correctOption, setCorrectOption] = useState<number | null>(null);
  const [isMediaWatched, setIsMediaWatched] = useState(false);
  const [videoHasError, setVideoHasError] = useState(false);
  const [currentWrongOptions, setCurrentWrongOptions] = useState<number[]>([]);
  const { getMistakesCount, addOneMistake } = useMistakesCount(0, [item.id]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isVideoUnsupported, setIsVideoUnsupported] = useState<boolean>(false);
  const [isAudio, setIsAudio] = useState<boolean>(false);

  const taskAnimationControl = useAnimation();
  const [ref, checkForResize] = useCheckForResize();
  const { onStartPlaying, onStopPlaying } = useHeartBeatMediaControl();
  const { playCorrectSound, playIncorrectSound } = usePlayAudioPlayer();
  const { checkIfMounted } = useIsMounted();

  const setVideoLoading = useCallback(() => {
    setIsLoading(true);
  }, [setIsLoading]);

  const setVideoPlaying = useCallback(() => {
    setIsLoading(false);
  }, [setIsLoading]);

  useVideoLoading({
    isPlaying,
    onVideoPlaying: setVideoPlaying,
    onVideoWaiting: setVideoLoading
  });

  const newTaskStart = useCallback(
    async (newUrl: string) => {
      setIsPlaying(false);
      await taskAnimationControl.start('hidden');
      setUrl(newUrl);
    },
    [taskAnimationControl]
  );

  const showTaskSequence = useCallback(async () => {
    await taskAnimationControl.start('prepared');
    await taskAnimationControl.start('show');
  }, [taskAnimationControl]);

  useEffect(() => {
    async function runNewTask() {
      if (checkIfMounted()) {
        setIsMediaWatched(false);
        setIsAudio(false);
        setCorrectOption(null);
        setCurrentWrongOptions([]);
        setVideoHasError(false);
        setIsVideoUnsupported(false);
        await newTaskStart(item.videoURL);
        setVideoPlaying();
        showTaskSequence();
      }
    }

    runNewTask();
  }, [item.videoURL, newTaskStart, showTaskSequence, checkIfMounted, setVideoPlaying]);

  const handlePlay = () => {
    setIsPlaying(true);
    onStartPlaying();
  };

  const handlePause = useCallback(() => {
    if (!checkIfMounted()) return;
    setIsPlaying(false);
    onStopPlaying();
  }, [onStopPlaying, checkIfMounted]);

  useStopOnLeave(handlePause);

  const handleMediaEnd = useCallback(() => {
    if (!checkIfMounted()) return;
    setIsPlaying(false);
    setIsMediaWatched(true);
    onStopPlaying();
  }, [checkIfMounted, onStopPlaying]);

  const handleVideoError = (error: Error) => {
    // policies for video play
    // https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/play

    if (isPlaying && error.name === 'NotAllowedError') {
      setIsPlaying(false);
    } else if (error.name === 'NotSupportedError') {
      setIsPlaying(false);
      setVideoHasError(true);
      setIsVideoUnsupported(true);
    }
  };

  const handleVideoPlayerError = useCallback(() => {
    setVideoHasError(true);
    onStopPlaying();
    taskAnimationControl.start('show');
  }, [onStopPlaying, taskAnimationControl]);

  const checkVideoExist = useCallback(() => {
    const videoElement = document.querySelector(
      `#${PLAYER_ID} video`
    ) as HTMLVideoElement;
    if (videoElement && videoElement.getVideoPlaybackQuality().totalVideoFrames === 0) {
      // Show audio icon
      setIsAudio(true);
    }
  }, []);

  const handleMediaReady = () => {
    if (!checkIfMounted() || isMediaWatched || isPlaying) return;

    checkVideoExist();
    checkForResize();
    setIsLoading(false);
    setIsPlaying(true);
  };

  const handleSelectAnswer = (optionId: number, mistakesCount: number) => {
    onSelectAnswer({
      itemId: item.id,
      optionId,
      mistakesCount
    });
  };

  const handleOptionClick = (optionId: number) => {
    if (optionId === undefined) return;
    const selectedOption = item.options.find((option) => option.id === optionId);
    if (selectedOption?.correct) {
      playCorrectSound();
      setCorrectOption(selectedOption.id);

      setTimeout(() => {
        taskAnimationControl.start('hidden');
        handleSelectAnswer(selectedOption.id, getMistakesCount());
        setIsMediaWatched(false);
      }, BUTTONS_ANIMATION_DURATION * 2);
    } else {
      playIncorrectSound();
      setCurrentWrongOptions((prev: number[]) => union(prev, [optionId]));
      addOneMistake();
    }
  };

  return {
    taskAnimationControl,
    ref,
    isLoading,
    isPlaying,
    videoHasError,
    isVideoUnsupported,
    handlePlay,
    isMediaWatched,
    handleMediaEnd,
    handleVideoError,
    handleVideoPlayerError,
    handlePause,
    url,
    handleMediaReady,
    handleOptionClick,
    correctOption,
    currentWrongOptions,
    isAudio
  };
}

export default useMinimalPairsItem;
