import React, { useMemo } from 'react';
import _sampleSize from 'lodash/sampleSize';
import Flashcard from './Flashcard';
import { IGameContainerProps } from '../types';

const MAX_WORDS_NUMBER = 12;

const FlashcardContainer: React.FC<IGameContainerProps> = ({
  taskId,
  phrases,
  maxRounds,
  onRoundComplete,
  onNext,
  className
}) => {
  const words = useMemo(
    () => _sampleSize([...phrases], maxRounds || MAX_WORDS_NUMBER),
    [maxRounds, phrases]
  );

  return words.length ? (
    <Flashcard
      taskId={taskId}
      words={words}
      onRoundComplete={onRoundComplete}
      onNext={onNext}
      className={className}
    />
  ) : null;
};

export default FlashcardContainer;
