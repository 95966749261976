import { ILeaderboardRow } from '@adeptlms/lingu-students-react-shared';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import cn from 'classnames';
import { Translate, t } from 'i18n';
import { FC } from 'react';
import Picture from 'students/views/shared/components/Picture';
import { SNoStyleButton, customMediaQuery } from 'students/views/shared/styled';
import styled from 'styled-components';
import crownIcon from '../../assets/crown_icon.svg';
import eyeOffIcon from '../../assets/eye_off_icon.svg';
import starActiveIcon from '../../assets/points_star_active_icon.svg';
import starIcon from '../../assets/points_star_icon.svg';

interface ITableItem {
  row: ILeaderboardRow;
  isActive: boolean;
  isHiddenProfile: boolean;
  className?: string;
}

const LeaderboardRow: FC<ITableItem> = ({
  row,
  isActive,
  isHiddenProfile,
  className
}) => (
  <SWrapper
    className={cn(className, { active: isActive })}
    data-testid="students/components/Leaderboard/LeaderboardRow"
  >
    <SUserBlock>
      {row.rowNumber === 1 ? (
        <SCrownIcon src={crownIcon} alt="1" />
      ) : (
        <SPlacePosition className={cn({ active: isActive })}>
          {row.rowNumber}
        </SPlacePosition>
      )}

      {row.student.avatarURL ? (
        <SUserAvatar src={row.student.avatarURL} alt="" />
      ) : (
        <SUserDefaultAvatar aria-hidden="true">
          <span>{row.student.initials}</span>
        </SUserDefaultAvatar>
      )}

      <SUserName className={cn({ active: isActive })}>{row.student.name}</SUserName>
    </SUserBlock>

    <div className="ln-flex ln-items-center ln-justify-end ln-gap-4">
      {isActive && isHiddenProfile ? (
        <Popover className="ln-relative">
          <PopoverButton as="div" className="ln-flex ln-items-center">
            <SNoStyleButton title={t('frontend.leaderboard.hidden_profile_msg')}>
              <SHiddenProfileIcon src={eyeOffIcon} />
            </SNoStyleButton>
          </PopoverButton>

          <PopoverPanel
            anchor="bottom"
            className="ln-z-[100] ln-flex ln-items-center ln-bg-white ln-shadow-card ln-rounded-lg ln-py-2 ln-px-4 !ln-max-w-64"
          >
            <Translate str="frontend.leaderboard.hidden_profile_msg" />
          </PopoverPanel>
        </Popover>
      ) : null}

      <SStarIcon
        className={cn({ active: isActive })}
        src={isActive ? starActiveIcon : starIcon}
        alt=""
      />

      <SPointsText
        className={cn({ active: isActive })}
        title={t('frontend.leaderboard.score')}
      >
        {row.xp < 0 ? 0 : row.xp}
      </SPointsText>
    </div>
  </SWrapper>
);

export default LeaderboardRow;

const SWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 9px 16px;
  background: transparent;
  width: 100%;

  &.active {
    background: rgba(255, 255, 255, 0.1);
  }

  @supports (display: grid) {
    display: grid;
    grid-template-columns: 77% 23%;
  }

  ${customMediaQuery('tablet')} {
    border-radius: 10px;
  }
`;

const SCrownIcon = styled(Picture)`
  width: 26px;
  height: 26px;
  min-width: 26px;
  margin-inline-end: 24px;
`;

const SUserBlock = styled.div`
  display: flex;
  align-items: center;
  margin-inline-end: 24px;
`;

const SUserAvatar = styled(Picture)`
  width: 36px;
  height: 36px;
  min-width: 36px;
  margin-inline-end: 8px;
  border-radius: 50%;
`;

const SUserDefaultAvatar = styled.div`
  width: 36px;
  height: 36px;
  min-width: 36px;
  border-radius: 50%;
  background: #4c5972;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-inline-end: 8px;

  & > span {
    font-size: 0.85rem;
    font-weight: 700;
    color: var(--color-white);
  }
`;

const SUserName = styled.p`
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.25rem;
  color: rgba(255, 255, 255, 0.7);
  margin: 0;
  padding: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &.active {
    color: var(--color-white);
  }
`;

const SPlacePosition = styled.p`
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1rem;
  color: rgba(255, 255, 255, 0.5);
  margin-block: 0;
  margin-inline: 0 10px;
  padding: 0;
  min-width: 40px;
  text-align: start;

  &.active {
    font-size: 1.25rem;
    line-height: 1.5rem;
    color: var(--color-white);
    margin-block: 0;
    margin-inline: 0 8px;
  }
`;

const SPointsText = styled.p`
  font-size: 1rem;
  font-weight: 700;
  line-height: 2.25rem;
  color: rgb(235, 233, 245);
  margin: 0;
  padding: 0;

  &.active {
    color: #fed02d;
  }
`;

const SHiddenProfileIcon = styled(Picture)`
  display: grid;
  margin: 0;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 100px;
  width: 28px;
  height: 28px;
  padding: 4px;
`;

const SStarIcon = styled(Picture)`
  display: grid;
  width: 20px;
  height: 20px;
  min-width: 20px;
  margin-inline-end: 10px;

  &.active {
    margin-inline-end: 0;
    margin-inline-start: -10px;
    margin-block-start: 3px;
    width: 40px;
    height: 40px;
    min-width: 40px;
  }
`;
