import { cloneDeep } from 'lodash';
import {
  ILessonTaskItemBase,
  IBaseTaskItemSession,
  ITaskSession
} from '@adeptlms/lingu-students-react-shared';

export function filterItemsBySessionItems<
  TaskItem extends ILessonTaskItemBase,
  SessionItem extends IBaseTaskItemSession
>(taskItems: TaskItem[], sessionTaskItems: SessionItem[]): TaskItem[] {
  const sessionItemsSetByItemId = new Set(
    sessionTaskItems.map((sessionTaskItem) => sessionTaskItem.taskItemId)
  );

  // show items that have item session
  const taskItemsIntersection = taskItems.filter((taskItem) =>
    sessionItemsSetByItemId.has(taskItem.id)
  );

  return taskItemsIntersection;
}

export function cleanSessionItemsByTaskItems<
  TaskItem extends ILessonTaskItemBase,
  SessionItem extends IBaseTaskItemSession
>(taskItems: TaskItem[], sessionTaskItems: SessionItem[]): SessionItem[] {
  const taskItemsSet = new Set(taskItems.map((taskItem) => taskItem.id));

  // order by items that comes in task
  const mappedIds = Array.from(taskItemsSet);

  // check if session items has its corresponding task items
  const sessionItemsIntersection = sessionTaskItems.filter((sessionTaskItem) =>
    taskItemsSet.has(sessionTaskItem.taskItemId)
  );

  const orderedItemsSessions = cloneDeep(sessionItemsIntersection).sort((a, b) => {
    return mappedIds.indexOf(a.taskItemId) - mappedIds.indexOf(b.taskItemId);
  });

  return orderedItemsSessions;
}

export function isLessonCompleted(taskSession?: ITaskSession): boolean {
  return taskSession ? taskSession?.lessonSession.status !== 'active' : false;
}
