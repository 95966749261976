import { useState, useEffect, useCallback, useRef } from 'react';
import { shuffle, cloneDeep } from 'lodash';
import {
  IArrangeTextItem,
  IArrangeTextItemOption,
  IArrangeTextTask,
  IArrangeTextTaskSession,
  tArrangeTextAnswer,
  tAnswer,
  IArrangeTextTaskItemSession
} from '@adeptlms/lingu-students-react-shared';
import { useTaskItems } from '../../../useTaskItems';

interface IReturnedObject {
  taskItems: IArrangeTextItem[];
  status?: 'active' | 'completed';
  handleDragEnd: (itemIndex: number, options: IArrangeTextItemOption[]) => void;
  handleNext: () => void;
  handleSkip: () => void;
}

export const useArrangeTextTask = (
  task: IArrangeTextTask,
  onNext: (answer: tAnswer) => void,
  taskSession?: IArrangeTextTaskSession
): IReturnedObject => {
  const [taskItems, setTaskItems] = useState<IArrangeTextItem[]>([]);

  const { taskItems: filteredTaskItems, sessionTaskItems } = useTaskItems<
    IArrangeTextItem,
    IArrangeTextTaskItemSession
  >(task.items, taskSession?.taskItemSessions);

  const answersRef = useRef<tArrangeTextAnswer>([]);
  const initialAnswersRef = useRef<tArrangeTextAnswer>([]);

  useEffect(() => {
    if (sessionTaskItems.length) {
      answersRef.current = sessionTaskItems;
    } else {
      answersRef.current = filteredTaskItems.map((item, index) => {
        return {
          completed: false,
          id: index,
          taskItemId: item.id,
          options: [],
          mistakesCount: 0,
          skipped: false,
          url: ''
        };
      });
    }

    const copiedTaskItems = cloneDeep(filteredTaskItems);
    if (taskSession?.status === 'completed') {
      copiedTaskItems.forEach((item, index) => {
        item.options.sort(
          (a, b) =>
            answersRef.current[index].options.indexOf(a.position) -
            answersRef.current[index].options.indexOf(b.position)
        );
      });
    } else {
      // shuffle task items
      copiedTaskItems.forEach((item) => {
        item.options = shuffle(item.options);
      });

      // prepare initial answers
      copiedTaskItems.forEach((taskItem, index) => {
        if (answersRef.current[index]) {
          answersRef.current[index].options = taskItem.options.map(
            (option) => option.position
          );
        }
      });
    }
    initialAnswersRef.current = cloneDeep(answersRef.current);
    setTaskItems(copiedTaskItems);
  }, [filteredTaskItems, sessionTaskItems, taskSession?.status]);

  const handleDragEnd = (itemIndex: number, options: IArrangeTextItemOption[]) => {
    const items = cloneDeep(taskItems);
    items[itemIndex].options = options;
    setTaskItems(items);
    const answer = answersRef.current[itemIndex];
    answersRef.current = [
      ...answersRef.current.slice(0, itemIndex),
      { ...answer, options: options.map((opt) => opt.position), skipped: false },
      ...answersRef.current.slice(itemIndex + 1)
    ];
  };

  const handleNext = useCallback(() => {
    onNext(answersRef.current);
  }, [onNext]);

  const handleSkip = useCallback(() => {
    onNext(
      initialAnswersRef.current.map((answer: IArrangeTextTaskItemSession) => ({
        ...answer,
        options: [...answer.options],
        skipped: true
      }))
    );
  }, [onNext]);

  return {
    taskItems,
    handleDragEnd,
    handleNext,
    handleSkip
  };
};
