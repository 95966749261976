import cn from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import shuffle from 'lodash/shuffle';
import { FC, useMemo } from 'react';
import { customMediaQuery } from 'students/views/shared/styled';
import styled from 'styled-components';

import { useArrangeItemsViewport } from '../../hooks/useArrangeItemsViewport';
import { IArrangeItemsViewport, IItemBlock } from '../../types';
import ArrangeItemsHint from '../ArrangeItemsHint';
import AnswerBlock from './AnswerBlock';
import AnswerItem, { SCROLLABLE_CONTAINER_CLASSNAME } from './AnswerItem';
import ItemBlock from './ItemBlock';

const MAX_ITEMS_IN_THE_ROW = 5;

const ArrangeItemsViewport: FC<IArrangeItemsViewport> = ({
  options,
  onAnswer,
  addOneMistake
}) => {
  const {
    viewportRef,
    itemBlocks,
    onDrag,
    onDragStart,
    onDragEnd,
    onBlockAnimationComplete,
    getHintMove,
    itemBlocksRefs,
    hintVisible,
    getHintAreas,
    answerItems,
    answersBlockRef
  } = useArrangeItemsViewport({ options, onAnswer, addOneMistake });

  const shuffledWordBlocks = useMemo(() => shuffle(itemBlocks), [itemBlocks]);

  return (
    <AnimatePresence>
      <SViewport ref={viewportRef}>
        <SViewportAnswersWrapper
          ref={answersBlockRef}
          className={cn(SCROLLABLE_CONTAINER_CLASSNAME, {
            flexCentered: itemBlocks.length / 2 < MAX_ITEMS_IN_THE_ROW
          })}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
        >
          {answerItems.map((answerItem: IItemBlock, index: number) => (
            <AnswerItem key={index} itemBlock={answerItem} />
          ))}

          {itemBlocks.map((itemBlock: IItemBlock) =>
            itemBlock.origin ? (
              <AnswerBlock
                key={itemBlock.optionId}
                itemBlock={itemBlock}
                ref={itemBlocksRefs[itemBlock.sequenceIndex]}
              />
            ) : null
          )}
        </SViewportAnswersWrapper>

        <SWordBlocksWrapper>
          {shuffledWordBlocks.map((itemBlock) =>
            !itemBlock.origin ? (
              <SItemRandomizedWrapper key={itemBlock.optionId}>
                <AnimatePresence>
                  <ItemBlock
                    sequence={itemBlock.sequenceIndex}
                    itemBlock={itemBlock}
                    onItemDrag={onDrag}
                    onItemDragStart={onDragStart}
                    onItemDragEnd={onDragEnd}
                    onItemAnimationComplete={onBlockAnimationComplete}
                    getHintMove={getHintMove}
                    ref={itemBlocksRefs[itemBlock.sequenceIndex]}
                  />
                </AnimatePresence>
              </SItemRandomizedWrapper>
            ) : null
          )}
        </SWordBlocksWrapper>

        {hintVisible && <ArrangeItemsHint getHintAreas={getHintAreas} />}
      </SViewport>
    </AnimatePresence>
  );
};

export default ArrangeItemsViewport;

const SViewport = styled.div`
  width: 100%;
  flex-grow: 1;
  position: relative;
  display: flex;
  flex-direction: column;

  ${customMediaQuery('tablet')} {
    margin-block-start: 100px;
    max-width: 660px;
  }
`;

const SViewportAnswersWrapper = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow-x: auto;
  overflow-y: hidden;
  margin: 0 -2rem;
  padding: 0 0 0 16px;
  position: relative;
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.4) rgba(0, 0, 0, 0.15);

  ${customMediaQuery('tablet')} {
    justify-content: flex-start;
    padding: 0 0 0 16px;
    margin: 0;
  }

  &.flexCentered {
    justify-content: center;
  }

  &::-webkit-scrollbar {
    background: transparent;
    height: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 10px;
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    height: 6px;
  }
`;

const SWordBlocksWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const SItemRandomizedWrapper = styled.div`
  height: 6rem;
  width: 100%;
  position: relative;
  margin-block-end: 1rem;

  ${customMediaQuery('tablet')} {
    height: 5rem;
    width: calc(50% - 2rem);
    margin-block-end: 2rem;

    &:nth-child(2n + 1) {
      margin-inline-end: 2rem;
    }
  }
`;
